import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

interface Props {
  cancelURL: string;
  loading: boolean;
  disabled: boolean;
}

export default function SubmitButtons(props: Props) {
  return (
    <div className="actions">
      <Button content="Cancel" data-testid="cancel-button" as={Link} to={props.cancelURL} />
      <Button content="Submit" data-testid="submit-button" disabled={props.disabled} positive loading={props.loading} />
    </div>
  )
}
