import React, { useEffect } from 'react';
import { Form, Container } from 'semantic-ui-react';
import { useStores } from '../../hooks/use-stores';
import SubmitButtons from './buttons/SubmitButtons';
import NameField from './fields/NameField';
import history from '../../history/history';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import Movie from '../../models/movie';
import Error from '../../models/error';
import ErrorMessage from './messages/ErrorMessage';

export default function EditMovie() {
  let { id } = useParams()
  const { moviesStore } = useStores();
  const [name, updateName] = React.useState("");
  const [loading, updateLoading] = React.useState(false)
  const [errorMessage, updateErrorMessage] = React.useState<Error>({ error: false, message: "", reason: "" })

  const { data, error, isValidating } = useSWR<Movie, any>(`movie:${id}`, () =>
    moviesStore.get(Number(id)),
    { revalidateOnFocus: false });

  useEffect(() => {
    if (data === undefined || data === null) return
    updateName(data.name)
  }, [data])

  if (error) return <p data-testid="error">Error :(</p>;
  if (!data && !isValidating) return <p data-testid="movie-not-found">not found</p>;
  if (!data) return <p data-testid="loading">Loading...</p>;

  async function submit() {
    updateLoading(true)
    try {
      const body = { ID: Number(id), name }
      await moviesStore.update(id, body)
      updateLoading(false)
      history.push('/movies')
    } catch (error) {
      console.log(error.statusText)
      updateErrorMessage({ error: true, message: error.message, reason: error.reason })
      updateLoading(false)
    }
  }

  return (
    <Container textAlign="left">
      <Form onSubmit={submit} data-testid="edit-movie-form">
        <NameField value={name} onChange={updateName} />
        <SubmitButtons cancelURL='/movies' disabled={false} loading={loading} />
        <ErrorMessage value={errorMessage} />
      </Form>
    </Container>
  );
}
