import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Header, Icon, Segment, Table } from 'semantic-ui-react';
import useSWR from "swr";
import { useStores } from '../../hooks/use-stores';
import Movie from '../../models/movie';

export default function ListMovies() {
  const { moviesStore } = useStores();
  const { data, error, revalidate } = useSWR("movies", () =>
    moviesStore.list()
  );

  async function remove(id: number) {
    await moviesStore.delete(id)
    revalidate()
  }

  if (error) return <p data-testid="error">Error :(</p>;
  if (!data) return <p data-testid="loading">Loading...</p>;

  return (
    <>
      <Header as='h3'>List Movies</Header>

      <Table striped compact color="blue">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Movie ID</Table.HeaderCell>
            <Table.HeaderCell>Movie Name</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.length === 0 &&
            <Table.Row>
              <Table.Cell colSpan='3'>
                <Segment placeholder basic>
                  <Header icon data-testid="no-movies">
                    <Icon name="film" />
                    No movies found
                  </Header>
                  <Button primary as={NavLink} to='/create/movie' data-testid="new-movie-button">New Movie</Button>
                </Segment>
              </Table.Cell>
            </Table.Row>
          }

          {data.map((movie: Movie) => (
            <Table.Row key={movie.ID} data-testid="movie">
              <Table.Cell>{movie.ID}</Table.Cell>
              <Table.Cell>{movie.name}</Table.Cell>
              <Table.Cell>
                <Button.Group primary floated="right">
                  <Button as={NavLink} to={`/movies/${movie.ID}`} data-testid="view-movie-button">View</Button>
                  <Dropdown
                    className='button icon'
                    // options={options}
                    trigger={<React.Fragment />}>
                    <Dropdown.Menu>
                      <Button className="square" fluid icon labelPosition='right' as={NavLink} to={`/edit/movie/${movie.ID}`} data-testid="edit-movie-button">
                        Edit
                        <Icon name='edit outline' />
                      </Button>
                      <Button className="square" icon labelPosition='right' onClick={() => remove(movie.ID as number)} data-testid="delete-movie-button">
                        Delete
                        <Icon name='trash alternate outline' />
                      </Button>
                    </Dropdown.Menu>
                  </Dropdown>
                </Button.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='3'>
              <Button secondary as={NavLink} to='/create/movie' floated='right'>New Movie</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
}
