import PropTypes from 'prop-types'
import React from 'react'
import { Message } from 'semantic-ui-react'
import Error from '../../../models/error'

interface Props {
  value: Error,
}

export default function ErrorMessage(props: Props) {
  if (props.value.error === false) return null
  return (
    <Message error negative data-testid="error-message">
      <Message.Header data-testid="error-message-header">{props.value.reason}</Message.Header>
      <p data-testid="error-message-text">{props.value.message}</p>
    </Message>
  )
}
ErrorMessage.propTypes = {
  value: PropTypes.object,
}
