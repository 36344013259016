import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import history from '../../history/history';
import { useStores } from '../../hooks/use-stores';
import useSWR from 'swr';
import Movie from '../../models/movie';

export default function ViewMovie() {
  let { id } = useParams<{ id: string }>()
  const { moviesStore } = useStores();

  const { data, error, isValidating } = useSWR<Movie, string>(`movie:${id}`, () =>
    moviesStore.get(Number(id))
  );

  async function remove(id: number) {
    await moviesStore.delete(id)
    history.push("/movies")
  }

  if (error) return <p data-testid="error">Error :(</p>;
  if (!data && !isValidating) return <p data-testid="movie-not-found">not found</p>;
  if (!data) return <p data-testid="loading">Loading...</p>;

  return (
    <>
      <Header as='h3'>View Movie</Header>
      <Container textAlign="left">
        <Header>Name: {data.name}</Header>

        <Button as={NavLink} to={`/movies`}>Back</Button>
        <Button as={NavLink} to={`/edit/movie/${id}`}>Edit</Button>
        <Button color="red" onClick={() => remove(Number(id))} data-testid="delete-movie-button">Delete
            <Icon name='trash alternate outline' />
        </Button>
      </Container>
    </>
  );
}
