import React from 'react';
import { Form } from 'semantic-ui-react';
import history from '../../history/history';
import { useStores } from '../../hooks/use-stores';
import Error from '../../models/error';
import SubmitButtons from './buttons/SubmitButtons';
import NameField from './fields/NameField';
import ErrorMessage from './messages/ErrorMessage';

export default function CreateMovie() {
  const { moviesStore } = useStores();
  const [name, updateName] = React.useState("");
  const [loading, updateLoading] = React.useState(false)
  const [errorMessage, updateErrorMessage] = React.useState<Error>({ error: false, message: "", reason: "" })
  async function submit() {
    updateLoading(true)
    try {
      const body = { name, ID: undefined }
      await moviesStore.create(body)
      updateLoading(false)
      history.push('/movies')
    } catch (error) {
      updateErrorMessage({ error: true, message: error.message, reason: error.reason })
      updateLoading(false)
    }
  }

  return (
    <Form data-testid="new-movie-form" error={errorMessage.error} onSubmit={submit}>
      <NameField value={name} onChange={updateName} />
      <SubmitButtons cancelURL='/movies' disabled={false} loading={loading} />
      <ErrorMessage value={errorMessage} />
    </Form>
  );
}
